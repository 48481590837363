<template>
 <div class="panel">
    <div class="main">
      <b-modal centered v-model='showDeleteUserModal' :contentClass='"w-35"'>
        <template #modal-header>
          <h4>Are you sure?</h4>
        </template>
        <template #default>
          <p>Are you sure you wish to remove {{ ProjectAdminToDelete.user.username || ProjectAdminToDelete.user.email }}
            as a project admin?</p>
        </template>
        <template #modal-footer>
          <b-button @click='closeDeleteUserModal' class='float-left'>Cancel</b-button>
          <b-button @click='deleteProjectAdmin' variant='danger' class='float-right'>Remove</b-button>
        </template>
      </b-modal>

      <div v-if="selectedProject == null" class="d-flex justify-content-center mt-5">
        <b-spinner variant='primary' style='width: 3rem; height: 3rem;'></b-spinner>
      </div>
      <section v-else class='w-80 m-auto p-5'>
        <div>
          <h4 class='d-inline'>Project Admins</h4>
            <div class='py-2 text-secondary'>
              The users listed below can edit, share, and assign new project admins to your project.
            </div>
        </div>

        <div class='d-flex flex-column h-100'>
          <b-row>
            <b-col lg="6">
                <b-input-group class="my-0">
                  <b-dropdown class="dropdown dropdown-canvas outline-primary bg-white border-white text-dark my-0" :disabled="allProjectAdministrators.choices.length == 0" :text="projectAdminPlaceholder" variant="light">
                    <b-dropdown-item-button
                      boundary="window"
                      class="dropdown-button"
                      variant="outline-secondary"
                      v-for="admin in allProjectAdministrators.choices"
                      :key="admin.id"
                      @click="selectUser(admin)"
                    >
                      {{ admin.username || admin.email }}
                    </b-dropdown-item-button>
                  </b-dropdown>
                  <b-button :disabled="addingAdmin || !selectedUserId" @click="addProjectAdmin()" variant="primary">
                    <b-spinner v-if='addingAdmin' class='spinner' small>
                    </b-spinner>
                    {{addingAdmin ? 'Adding' : 'Add'}}
                    </b-button>
                </b-input-group>
            </b-col>
          </b-row>
        </div>

        <b-table-simple small class='border' style='font-size: 0.875rem;'>
          <b-thead style='background-color: #E9ECEF;'>
            <b-tr>
              <b-th class='pl-4' colspan='2'>Username/Email</b-th>
              <b-th>First Name</b-th>
              <b-th>Last Name</b-th>
              <b-th>Delete</b-th>
            </b-tr>
          </b-thead>
          <b-tbody class='bg-white mh-50 overflow-auto'>
            <b-tr v-for='admin in allProjectAdministrators.added' :key='admin.id'>
              <b-th class='font-weight-light pl-4 align-middle' colspan='2'>{{ admin.user.username || admin.user.email
              }}</b-th>
              <b-th class='font-weight-light align-middle'>{{ admin.user.first_name }}</b-th>
              <b-th class='font-weight-light align-middle'>{{ admin.user.last_name }}</b-th>

              <b-th class='text-left pl-4 align-middle'>
                <b-spinner v-if='deletingAdminId == admin.id' small>
                </b-spinner>
                <b-icon v-else icon='trash' role='button' @click='openDeleteUserModal(admin)'>
                </b-icon>
              </b-th>
            </b-tr>
          </b-tbody>
        </b-table-simple>

      </section>
      </div>
    <div class="side-panel">
        <h6 class='pt-5'>Organization Admins</h6>
        <hr>
        <ul class="fine-text" v-for='admin in allOrgAdmins' :key='admin.id'>
          <li>{{ admin.username || admin.email }}</li>
        </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { EventBus } from '@/network/eventbus';

export default {
  name: 'ProjectSettingsAdmin',
  data() {
    return {
      addingAdmin: false,
      showDeleteUserModal: false,
      deletingAdminId: null,
      selectedUserId: null,
      projectAdminPlaceholder: 'Please Select'
    };
  },
  methods: {
    openDeleteUserModal(selectedUserId) {
      this.showDeleteUserModal = true;
      this.ProjectAdminToDelete = selectedUserId;
    },
    closeDeleteUserModal() {
      this.showDeleteUserModal = false;
    },
    selectUser(user) {
      this.projectAdminPlaceholder = user.username || user.email;
      this.selectedUserId = user.id;
    },
    async deleteProjectAdmin() {
      this.deletingAdminId = this.ProjectAdminToDelete.id;
      const projectAdminId = this.deletingAdminId;
      const projectId = this.projectId;
      this.closeDeleteUserModal();
      await this.$store.dispatch('project/deleteProjectAdministrator', {projectId, projectAdminId});
      this.deletingAdminId = null;
    },
    async addProjectAdmin() {
      this.addingAdmin = true;
      const projectId = this.projectId;
      const newAdmin = {
        user: this.selectedUserId
      };
      await this.$store.dispatch('project/createProjectAdministrator', {projectId, newAdmin});
      this.addingAdmin = false;
      this.projectAdminPlaceholder = 'Please Select';

      if (this.createProjectAdminError || this.projectAdminDeletedError) {
        EventBus.$emit('TOAST', {
          variant: 'danger',
          content: `Failed to add ${this.selectedUserId} as a new project admin`
        });
      } else {
        EventBus.$emit('TOAST', {
          variant: 'success',
          content: `Successfully added ${this.selectedUserId} as a new project admin`
        });
      }
    },
  },
  computed: {
    projectId() {
      return Number(this.$route.params.id);
    },
    allProjectAdministrators() {
      let filteredAdmins = [];
      for (const user of this.projectAdministrators) {
        filteredAdmins.push(user.user.id);
      }
      const projectCompanyUsers = this.company ? this.company.users : [];
      let choices = projectCompanyUsers.filter(admin => !filteredAdmins.includes(admin.id));
      choices.sort(function(a, b) {
        const nameA = a.username?.toUpperCase() || a.email?.toUpperCase();
        const nameB = b.username?.toUpperCase() || b.email?.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      const projectAdmins = {
        'choices': choices,
        'added': this.projectAdministrators
      };

      return projectAdmins;
    },
    allOrgAdmins() {
      let usersWithRoles = [];
      const projectCompanyUsers = this.company ? this.company.users : [];
      for (const user of projectCompanyUsers) {
        if (user.role) {
          usersWithRoles.push(user);
        }
      }
      return usersWithRoles.filter(user => user.role.includes('role: org admin'));
    },
    ...mapGetters([
      'loggedInUser',
      'allowedPermissions',
      'company'
    ]),
    ...mapGetters('project', [
      'selectedProject',
      'projectAdministrators',
      'projectAdminCreated',
      'projectAdminDeleted',
      'projectAdminDeletedError',
      'createProjectAdminError'
    ])
  }
};
</script>

<style scoped>
.panel {
  margin: 0;
  background-color: #fff;
  min-height: 40em;
  display: flex;
}

.main {
  padding: 1rem;
  width: calc(100% - 20rem);
}

.loading-spinner {
  all: unset;
}

.side-panel {
  background-color: var(--grey-200);
  margin: 0;
  padding: 1rem;
  width: 20rem;
}

.dropdown-button {
  width: calc(100% - 3rem);
}

.dropdown-canvas /deep/ .dropdown-menu {
  overflow-y: scroll;
  max-height: 37.5rem;
}

.loading-spinner-container {
  margin-left: 50%;
  margin-top: 2.5%;
  margin-bottom: 2.5%;
}
</style>